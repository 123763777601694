import { jwtDecode } from 'jwt-decode';
import axios, { setToken } from 'shared/utils/axios';
import logout from 'shared/actions/user/logout';
import getAllUrlParams from 'shared/utils/queryString';
import getAppRouting from 'shared/utils/AppRouting';
// import 'moment/locale/pl';
import { loginSuccess } from 'shared/actions/user/login';
import getToken, { getExpires } from 'shared/utils/user/getToken';
import parse from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import { checkEnv } from 'shared/getEnv';
import space from 'shared/utils/space';
import store from './store';
import initSentry from './init/sentry';
import i18next from './init/i18next';
import './init/adminLogin';
import setModes from './setModes';

// import * as OfflinePluginRuntime from 'offline-plugin/runtime';

// OfflinePluginRuntime.install();
axios.defaults.withCredentials = !checkEnv('DISABLE_HTTPS');

const urlParams = getAllUrlParams(window.location.search);
initSentry();

// HACK: Use this to find elements crashing app with mutation done by Google Translate
// applyMutationHack();
const { locale = 'pl' } = store.getState();
const modes = setModes(store, urlParams);

/**
 * Add token to all axios requests
 */

const appRouting = getAppRouting({ debug: modes.debug, store });

// @ts-ignore
window.AppRouting = appRouting;
// @ts-ignore
window.space = space;

const checkLogin = async () => {
  try {
    const token = getToken();
    const expires = getExpires();
    if (!token || !expires) {
      if (urlParams?.login_page === 'nn') {
        window.location.href = `${window.location.origin}/nn`;
      }
      store.dispatch(logout());
      return;
    }
    const now = new Date();
    const expiresDate = parse(expires);
    if (!isBefore(now, expiresDate)) {
      store.dispatch(logout());
      return;
    }
    const decodedToken = jwtDecode(token);
    await store.dispatch(loginSuccess({ aud: decodedToken.aud }));
  } catch (ex) {
    // Catch storage errors;
    console.error(ex);
  }
};

const startApp = async () => {
  setToken(store, undefined, undefined, logout);
  const promises = [import('./renderApp'), checkLogin(), i18next(locale)];
  const results = await Promise.all(promises);
  results[0].default();
};

export default startApp;
